import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import OldStyle from "components/old-style"
import SEO from "components/seo"
import {
  ChangelogContainer,
  ChangelogHeader,
  Posts,
  Post,
} from "components/changelog"

export default function ChangelogTemplate({ data }) {
  const { frontmatter, html } = data.markdownRemark

  return (
    <OldStyle>
      <SEO title={frontmatter.date} />
      <ChangelogContainer>
        <div>
          <ChangelogHeader />
          <Posts>
            <div>
              <hr />
              <Post>
                <div className="left">
                  <h4>
                    <Link to="/changelog/">← Back to all posts</Link>
                  </h4>
                  {frontmatter.version ? (
                    <>
                      <h3>v{frontmatter.version}</h3>
                      <h4>{frontmatter.prettydate}</h4>
                    </>
                  ) : (
                    <h3>{frontmatter.prettydate}</h3>
                  )}
                </div>
                <div
                  className="post prose"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </Post>
            </div>
          </Posts>
        </div>
      </ChangelogContainer>
    </OldStyle>
  )
}
ChangelogTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ChangelogPostBySlug($name: String!) {
    markdownRemark(fields: { name: { eq: $name } }) {
      html
      frontmatter {
        version
        date(formatString: "YYYY-MM-DD")
        prettydate: date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
